<template>
  <div>
    <div class="box-setting-header index-style-user">
      <div class="title ml-4 mt-2">Gérer les utilisateurs</div>
      <div>
        <b-button
          v-show="selectedMenu == 'permission'"
          variant="success"
          class="config-btn ml-2"
          @click="handleClickModalAddUserPermission"
          v-b-modal.addUserPermission
        >
          <font-awesome-icon icon="plus" /> Ajouter un ensemble
          d'autorisations</b-button
        >
        <b-button
          v-show="selectedMenu == 'users'"
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addUserModal
          ><font-awesome-icon icon="plus" /> Ajouter un utilisateur</b-button
        >
        <b-modal
          id="addUserModal"
          ref="addUserModal"
          title="Ajouter un utilisateur"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un utilisateur</div>
            <div class="iconClose" @click.prevent="hideModal('addUserModal')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="hendleSubmitUser"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom"
                  label-for="nom"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="nom"
                    v-model="userToAdd.name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="E-mail"
                  label-for="email"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="tel"
                    v-model="userToAdd.email"
                    required
                    type="email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Téléphone"
                  label-for="phone"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="phone"
                    v-model="userToAdd.phone"
                    type="tel"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Mot de passe"
                  label-for="pasword"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="password"
                    v-model="userToAdd.password"
                    required
                    type="password"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Confirmation de mot de passe"
                  label-for="confirmationPassword"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="confirmationPassword"
                    v-model="userToAdd.confirmationPassword"
                    required
                    type="password"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Type"
                  label-for="type"
                  class="input-modal-champ "
                >
                  <b-form-select
                    id="type"
                    v-model="userToAdd.role"
                    :options="listType"
                    required
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style ">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
        <b-modal
          ref="addUserPermission"
          id="addUserPermission"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          modal-class="cutsom-modal-bootstrap modal-permission"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un ensempble d'autorisation</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addUserPermission')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="hendleSubmitPermission"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                  class="input-modal-champ mb-2"
                >
                  <b-form-input
                    id="name"
                    v-model="permessionToAdd.permission"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Utilisateur"
                  label-for="user"
                  class="input-modal-champ mt-1"
                ></b-form-group>
                <multiselect
                  v-model="permessionToAdd.users"
                  :options="usersWithoutPermission"
                  placeholder="Rechercher l'utilisateur"
                  :multiple="true"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="true"
                  label="full_name"
                  track-by="id"
                  :preselect-first="false"
                  :loading="loadingUserWithoutPermission"
                >
                </multiselect>
                <b-form-group
                  label="Interface"
                  label-for="page"
                  class="input-modal-champ mt-1"
                ></b-form-group>

                <multiselect
                  v-model="permessionToAdd.page"
                  :options="[...ComputedListInterface]"
                  placeholder="Rechercher un interface"
                  :multiple="true"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="true"
                  label="name"
                  track-by="name"
                  :preselect-first="false"
                >
                </multiselect>
                <div
                  class="box-items-permission"
                  v-if="permessionToAdd && permessionToAdd.page"
                >
                  <div
                    class="box-item-permission"
                    v-for="page in permessionToAdd.page"
                    :key="page.name"
                  >
                    <div>
                      <div class="item-permission-title">{{ page.name }}</div>
                      <b-form-group>
                        <div>
                          <b-form-checkbox-group
                            switches
                            v-model="page.value"
                            :options="[...computedListPermission(page.name)]"
                            name="flavour-1"
                          ></b-form-checkbox-group>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style ">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="sub-menu-simple">
      <div class="nav-bar">
        <div class="menu-links">
          <div class="menu-link" @click="clickMenu('users')">
            <router-link class="menu-item" to="/setting/users">
              Utilisateurs
            </router-link>
          </div>
          <div class="menu-link" @click="clickMenu('permission')">
            <router-link class="menu-item" to="/setting/users/permissions">
              Ensembles d'autorisations
            </router-link>
          </div>
        </div>
      </div>

      <div class="body">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissionModel from '../../../models/permisionModel';
export default {
  name: 'Gestion-des-utilisateurs',
  data() {
    return {
      selectedMenu: 'users',
      loading: false,
      error: null,
      userToAdd: {
        nom: null,
        email: null,
        phone: null,
        password: null,
        confirmationPassword: null,
        type: null
      },
      //update  this list in user.vue
      listType: [
        { text: 'Comptabilité', value: 'contabilite' },
        { value: 'responsable_filiale', text: 'Responsable Filiale' },
        { value: 'responsable_novae', text: 'Responsable NOVAE' },
        { value: 'responsable_cactus', text: 'Responsable CACTUS' },
        { value: 'responsable_web_abondan', text: 'Responsable PINKMAN' },
        { value: 'responsable_iso_33', text: 'Responsable YNAN TECHNIQUE' },
        { value: 'responsable_rge', text: 'Responsable RGE' },
        { value: 'responsable_dnh', text: 'Responsable GE CDG' },
        { value: 'responsable_sav_s', text: 'Responsable SAMI-B' },
        {
          value: 'responsable_web_abondance',
          text: 'Responsable WEB ABONDANCE'
        },
        { value: 'responsable_corpedys', text: 'Responsable CORPEDYS' },
        { value: 'responsable_acteo', text: 'Responsable ALTEO FRANCE' },
        { value: 'responsable_ge_compta', text: 'Responsable GE COMPTA' },
        { value: 'responsable_ge_rh', text: 'Responsable GE RH' },
        {
          value: 'responsable_bureau_etude',
          text: "Responsable Bureau d'etude"
        },
        { value: 'responsable_mairie', text: 'Responsable NOVAE MAIRIE' },
        { value: 'responsable_gse', text: 'Responsable GSE' },
        { value: 'responsable_artisans', text: 'Responsable Artisans verts' },
        { value: 'responsable_ge_rh_compta', text: 'Responsable GE RH COMPTA' },
        {
          value: 'responsable_upcube_technologies',
          text: 'Responsable UPCUBE TECHNOLOGIES'
        },
        {
          value: 'responsable_controle_de_gestion',
          text: 'Responsable controle de gestion'
        },
        {
          value: 'responsable_finance',
          text: 'Responsable finance'
        },
        {
          value: 'utilisateur_iso33',
          text: 'Utilisateur ISO33'
        },
        {
          value: 'utilisateur_iso33',
          text: 'Utilisateur ISO33'
        },
        {
          value: 'utilisateur_isodit ',
          text: 'Utilisateur ISODIT'
        },
        {
          value: 'utilisateur_effimax ',
          text: 'Utilisateur EFFIMAX'
        },
        {
          value: 'utilisateur_CWF_CONSULTING',
          text: 'Utilisateur CWF CONSULTING'
        },
        {
          value: 'responsable_validation',
          text: 'Responsable validation'
        }
      ],
      permessionToAdd: {
        permission: null,
        page: [],
        users: []
      },
      getListePermission: [],
      getListePermissionToUse: []
    };
  },
  methods: {
    ...mapActions(['addUser', 'addNewPermission', 'getUsersWithoutPermission']),
    clickMenu(menu) {
      this.selectedMenu = menu;
    },
    resetModal() {
      this.userToAdd = {
        name: null,
        email: null,
        phone: null,
        password: null,
        confirmationPassword: null,
        role: null
      };
      this.error = null;
      this.permessionToAdd = {
        permission: null,
        page: [],
        users: []
      };
      this.getListePermission = permissionModel.create().list;
      this.getListePermissionToUse = permissionModel.create().list;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async hendleSubmitUser() {
      this.loading = true;
      this.error = null;
      if (this.userToAdd.password != this.userToAdd.confirmationPassword) {
        this.loading = false;
        this.error = [
          {
            password:
              'le mot de passe et la confirmation du mot de passe doivent être égaux'
          }
        ];
        return;
      }
      if (this.userToAdd.password.length < 6) {
        this.loading = false;
        this.error = [
          { password: 'le mot de passe doit contenir au moins 6 caractères' }
        ];
        return;
      }
      const responce = await this.addUser(this.userToAdd);
      if (responce.success) {
        this.loading = false;
        this.hideModal('addUserModal');
      } else {
        this.loading = false;
        this.error = responce.error;
      }
    },
    async hendleSubmitPermission() {
      this.loading = true;
      this.error = null;
      if (this.permessionToAdd.users.length == 0) {
        this.loading = false;
        this.error = [
          {
            Utilisateur: 'user required'
          }
        ];
        return;
      }
      if (this.permessionToAdd.page.length == 0) {
        this.loading = false;
        this.error = [
          {
            interface: 'interface required'
          }
        ];
        return;
      }
      const response = await this.addNewPermission(this.permessionToAdd);
      if (response.succes) {
        this.loading = false;
        this.hideModal('addUserPermission');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    handleClickModalAddUserPermission() {
      this.getUsersWithoutPermission();
    }
  },
  computed: {
    ...mapGetters([
      'getUserData',
      'users',
      'usersWithoutPermission',
      'loadingUserWithoutPermission'
    ]),
    ComputedListInterface: function() {
      return this.getListePermissionToUse.filter(page => {
        if (this.permessionToAdd.page) {
          return (
            this.permessionToAdd.page.findIndex(
              item => item.name === page.name
            ) < 0
          );
        }
      });
    },
    computedListPermission: function() {
      return function(data) {
        let table = this.getListePermission.filter(item => item.name == data);
        return table[0].value;
      };
    }
  },
  components: {
    Card: () => import('../../component/card')
  },
  mounted() {
    if (this.$route.path === '/setting/users/permissions') {
      this.selectedMenu = 'permission';
    } else {
      this.selectedMenu = 'users';
    }
    this.getListePermission = permissionModel.create().list;
    this.getListePermissionToUse = permissionModel.create().list;
  }
};
</script>

<style scoped lang="scss">
.box-items-permission {
  margin-top: 10px;
  .box-item-permission {
    padding: 5px;
    border: 1px solid #e8e6f2;
    border-radius: 10px;
    margin-bottom: 5px;
    display: flex;
    color: black;
    justify-content: space-between;
    .item-permission-title {
      text-align: start;
      text-align: start;
      font-size: 14px;
      margin-bottom: 7px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
    }
    .icon {
      align-self: center;
    }
  }
}
</style>
<style lang="scss">
.modal-permission {
  .modal-dialog {
    max-width: 550px !important;
    margin: auto !important;
  }
}
.multiselect__tag {
  background: #4d4bac;
}
.multiselect__tag-icon:after {
  color: #ffffff;
}
.box-items-permission {
  .box-item-permission {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #4d4bac !important;
      background-color: #4d4bac !important;
    }
  }
}
</style>
